import { GetServerSidePropsContext, GetServerSidePropsResult, NextPage } from 'next'
import React, { Fragment } from 'react'
import { Layout } from '@wh/common/chapter/components/Layouts/Layout'
import { DesktopBreadcrumbs } from '@bbx/search-journey/sub-domains/search/components/common/result-list/DesktopBreadcrumbs/DesktopBreadcrumbs'
import { SkyscraperLayout } from '@wh/common/chapter/components/Layouts/SkyscraperLayout'
import { concatPathWithQueryParams, getFirstString } from '@wh/common/chapter/lib/urlHelpers'
import { getAlertSearchResult, getSeoSearchResult } from '@bbx/search-journey/common/api/searchApiClient'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { DealerProfile } from '@bbx/ad-in/organisation/types/DealerProfile'
import { getDealerProfileBySlug } from '@bbx/ad-in/organisation/api/dealerProfileApiClient'
import { AdvertSummaryList } from '@bbx/common/types/ad-detail/AdvertSummary'
import { DealerProfilePageContent } from '@bbx/ad-in/organisation/components/DealerProfilePageContent'
import { dealerProfileAutoBreadcrumbs } from '../../../gebrauchtwagen/gebrauchtwagenhaendler-detail-auto'
import { verticals } from '@wh/common/chapter/types/verticals'

type DealerProfileBySlugProps = {
    dealerAds: AdvertSummaryList
    searchResult: SearchResult
    dealerProfile: DealerProfile
}

export const DealerProfilePageBySlug: NextPage<DealerProfileBySlugProps> & { Layout: typeof Layout } = (props) => (
    <Fragment>
        <DesktopBreadcrumbs breadcrumbs={dealerProfileAutoBreadcrumbs} />
        <DealerProfilePageContent dealerAds={props.dealerAds} searchResult={props.searchResult} dealerProfile={props.dealerProfile} />
    </Fragment>
)

export const getServerSideProps = async (
    context: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<DealerProfileBySlugProps>> => {
    const { query, req, res, resolvedUrl } = context

    const { slug, type, seopath, alertId, ...remainingQuery } = query

    if (!slug || slug instanceof Array || !type || type instanceof Array) {
        if (res) {
            res.statusCode = 404
        }
        return { notFound: true }
    }

    if (!['auto', 'motorrad', 'nutzfahrzeuge', 'wohnwagen-wohnmobile'].includes(type)) {
        return { notFound: true }
    }

    const path = ['/haendler', slug, type, ...((seopath ?? []) as string[])].join('/')
    const searchAgentId = getFirstString(alertId)

    try {
        const dealerAdsQuery = { rows: '30', page: '1' }
        const concatenatedPath = concatPathWithQueryParams(path, remainingQuery)
        const dealerAdsPath = concatPathWithQueryParams(['/haendler', slug, type].join('/'), { ...dealerAdsQuery })
        const dealerAdsPromise = getSeoSearchResult(dealerAdsPath, req)
        const searchResultPromise = searchAgentId ? getAlertSearchResult(query, req) : getSeoSearchResult(concatenatedPath, req)
        const dealerProfilePromise = getDealerProfileBySlug(slug, verticals.MOTOR, req)

        const [searchResult, dealerProfile, dealerAds] = await Promise.all([searchResultPromise, dealerProfilePromise, dealerAdsPromise])

        // Redirect to the current Personalized Dealer URL if slug of organisation is out-dated
        if (dealerProfile.organisation.slug) {
            if (!resolvedUrl.startsWith(`/iad/haendler/${dealerProfile.organisation.slug}`)) {
                const redirectUrl = concatPathWithQueryParams(`/iad/haendler/${dealerProfile.organisation.slug}/${type}`, {
                    alertId,
                    ...remainingQuery,
                })
                return { redirect: { destination: redirectUrl, permanent: true } }
            }
        }

        return { props: { dealerAds: dealerAds.advertSummaryList, searchResult, dealerProfile } }
    } catch (error) {
        const apiError = wrapInApiErrorIfNecessary(error)

        if (apiError.statusCode && apiError.statusCode >= 400 && apiError.statusCode <= 499) {
            if (res) {
                res.statusCode = 404
            }
            return { notFound: true }
        }

        throw apiError
    }
}

DealerProfilePageBySlug.Layout = SkyscraperLayout

export default DealerProfilePageBySlug
