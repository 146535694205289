
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/iad/haendler/[slug]/[type]/[[...seopath]]",
      function () {
        return require("private-next-pages/iad/haendler/[slug]/[type]/[[...seopath]].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/iad/haendler/[slug]/[type]/[[...seopath]]"])
      });
    }
  